import React from 'react';
import { Link } from 'gatsby';
import FormSection from './form-section';

export default function UserFormSection({ user }) {
  const { email } = user;
  return (
    <FormSection title="Account details">
      <div className="text-gray-800 block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center">
        <p className="text-base mb-4">You are currently signed in as <span className="text-gray-900 font-medium">{email}</span>.</p>
        <p className="text-sm">Not you? <Link to="/sign-out?redir=/checkout" className="font-medium text-indigo-600 hover:text-indigo-500">Sign out &raquo;</Link></p>
      </div>
    </FormSection>
  )
}