import React from 'react';
import NumberFormat from 'react-number-format';
import DiscountCodeForm from './discount-code-form';
import ProductListItem from './product-list-item';

export default function OrderSummary({ products, subtotal, discountCode, discountAmount, total, removeItem, checkDiscount, checking }) {
  return (
    <section aria-labelledby="summary-heading" className="hidden bg-gray-50 w-full max-w-md flex-col lg:flex">
      <h2 id="summary-heading" className="sr-only">
        Order summary
      </h2>

      <ul className="flex-auto overflow-y-auto divide-y divide-gray-200 px-6">
        {products.map((product) => <ProductListItem key={product.id} product={product} removeItem={removeItem} />)}
      </ul>

      <div className="sticky bottom-0 flex-none bg-gray-50 border-t border-gray-200 p-6">
        <DiscountCodeForm checkDiscount={checkDiscount} checking={checking} />

        <dl className="text-sm font-medium text-gray-500 mt-10 space-y-6">
          <div className="flex justify-between">
            <dt>Subtotal</dt>
            <dd className="text-gray-900"><NumberFormat value={subtotal} fixedDecimalScale decimalScale={2} displayType="text" thousandSeparator prefix="$" /></dd>
          </div>
          <div className="flex justify-between">
            <dt className="flex">
              Discount
              {!!discountCode && (
                <span className="ml-2 rounded-full bg-gray-200 text-xs text-gray-600 py-0.5 px-2 tracking-wide">
                  {discountCode}
                </span>
              )}
            </dt>
            {!!discountCode && (
              <dd className="text-gray-900">-<NumberFormat value={discountAmount} fixedDecimalScale decimalScale={2} displayType="text" thousandSeparator prefix="$" /></dd>
            )}
            {!discountCode && (
              <dd className="text-gray-900">--</dd>
            )}
          </div>
          <div className="flex items-center justify-between border-t border-gray-200 text-gray-900 pt-6">
            <dt>Total</dt>
            <dd className="text-base"><NumberFormat value={total} displayType="text" fixedDecimalScale decimalScale={2} thousandSeparator prefix="$" /></dd>
          </div>
        </dl>
      </div>
    </section>
  );
}