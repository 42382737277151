import { isBrowser, roundLikePhp } from './helpers';

const defaultCart = { items: [], discount: null };

export const getCart = () => isBrowser && window.localStorage.cart ? JSON.parse(window.localStorage.cart) : { ...defaultCart };

const setCart = (cart) => { window.localStorage.cart = JSON.stringify(cart); }
export const emptyCart = () => isBrowser && setCart({ ...defaultCart });

const findItemIndex = (items, id) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) {
      return i;
    }
  }
  return -1;
}


export const addCartItem = (item) => {
  const cart = getCart();
  const existingIndex = findItemIndex(cart.items, item.id);
  // For now, limit each item to being in the cart once
  if (existingIndex > -1) return cart;
  // Add the item
  cart.items.push({
    ...item,
    quantity: 1, // right now we are enforcing ONE for quantity
  });
  setCart(cart);
  return cart;
};

export const setDiscount = (discountCode) => {
  const cart = getCart();
  cart.discount = discountCode;
  setCart(cart);
  return cart;
}

export const clearDiscount = () => {
  const cart = getCart();
  cart.discount = null;
  setCart(cart);
  return cart;
}


export const removeCartItem = (id) => {
  const cart = getCart();
  const index = findItemIndex(cart.items, id);
  if (index > -1) {
    const newItems = [...cart.items];
    newItems.splice(index, 1);
    cart.items = newItems;
    setCart(cart);
  }
  return cart;
}

export const calculateSubtotal = () => {
  const cart = getCart();
  const subtotal = cart.items.reduce((total, item) => {
    const isFree = !!(1 * item.free);
    if (isFree) return total;
    return total + item.price * item.quantity;
  }, 0);
  return subtotal || 0.00;
}

export const calculateDiscount = (subtotal) => {
  const cart = getCart();
  let discountValue = 0;
  if (cart && cart.discount) {
    // We have a discount!  Let's calculate it
    const { discount } = cart;
    if (discount.type === 'percent') {
      discountValue = roundLikePhp((0.01 * discount.value) * subtotal, 2);
      // console.log(discountValue, 'discount value');
    } else if (discount.type === 'absolute') {
      discountValue = 1 * discount.value;
    }
  }
  // Ensure we never return a value greater than our subtotal
  return discountValue > subtotal ? subtotal : discountValue;
}

export const calculateTotal = () => {
  const subtotal = calculateSubtotal();
  const discount = calculateDiscount(subtotal);
  return subtotal - discount;
}

export const destroyCart = () => window.localStorage.removeItem('cart');
