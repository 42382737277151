import React from 'react';
import { Link } from 'gatsby';
import Field from '../form/field';
import OrDivider from './or-divider';
import FormSection from './form-section';

export default function AccountFormSection({ formik, teamRegistration }) {
  return (
    <FormSection title="Account details" >
      <Link
        to="/sign-in?redir=/checkout"
        className="w-full flex items-center justify-center bg-blue-500 border border-transparent text-white rounded-md py-2 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900"
      >
        Sign in to your account
      </Link>

      <OrDivider />

      <h4 className="text-lg font-medium text-gray 900 mb-4">{teamRegistration ? `Team Captain -  ` : ''} Create a new account</h4>

      <div className="grid grid-cols-12 gap-y-6 gap-x-4">
        <div className="col-span-full sm:col-span-6">
          <Field
            formik={formik}
            field={{
              name: 'first_name',
              type: 'text',
              label: 'First name',
            }}
          />
        </div>
        <div className="col-span-full sm:col-span-6">
          <Field
            formik={formik}
            field={{
              name: 'last_name',
              type: 'text',
              label: 'Last name',
            }}
          />
        </div>
        <div className="col-span-full sm:col-span-6">
          <Field
            formik={formik}
            field={{
              name: 'birthday',
              type: 'date',
              label: 'Your birthday',
            }}
          />
        </div>
        <div className="col-span-full sm:col-span-6">
          <Field
            formik={formik}
            field={{
              name: 'phone',
              type: 'phone',
              label: 'Mobile phone',
            }}
          />
        </div>
        <div className="col-span-full sm:col-span-6">
          <Field
            formik={formik}
            field={{
              name: 'email',
              type: 'email',
              label: 'Email',
              helpText: 'You will use this email to sign in.',
            }}
          />
        </div>
        <div className="col-span-full sm:col-span-6">
          <Field
            formik={formik}
            field={{
              name: 'password',
              type: 'password',
              label: 'Password',
              helpText: 'Enter a strong password that you will remember.',
            }}
          />
        </div>
      </div>
    </FormSection>
  );
}