import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import NumberFormat from 'react-number-format';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LockClosedIcon } from '@heroicons/react/solid';
import Alert from '../components/alerts/alert';
import Container from '../components/layout/default/container';
import { getCart, calculateSubtotal, calculateDiscount, calculateTotal, emptyCart, setDiscount } from '../utils/cart';
import { postApiRequest } from '../utils/request';
import { validatePhone } from '../utils/helpers';
import { setUser, setJwt, getCurrentUser, isLoggedIn } from '../utils/auth';
import { useNotifications } from '../contexts/notifications-provider';
import OrderSummary from '../components/checkout/order-summary';
import AccountFormSection from '../components/checkout/account-form-section';
import UserFormSection from '../components/checkout/user-form-section';
import MobileOrderSummary from '../components/checkout/mobile-order-summary';
import FormSection from '../components/checkout/form-section';
import Field from '../components/form/field';
import LoadingModal from '../components/overlays/loading-modal';
import SEO from '../components/layout/seo';
import TeamMemberFormSection from '../components/checkout/team-member-form-section';
import SmartTeamRegistrationSection from '../components/checkout/smart-team-registration-section';


export default function Checkout() {
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkingDiscount, setCheckingDiscount] = useState(false);
  const [formError, setFormError] = useState(null);
  const { addNotification } = useNotifications();
  const stripe = useStripe();
  const elements = useElements();
  const cart = getCart();
  const { items: products, discount } = cart;
  const subtotal = calculateSubtotal();
  const discountAmount = calculateDiscount(subtotal);
  const discountCode = discount ? discount.code : null;
  const total = calculateTotal();

  const user = getCurrentUser();
  const hasUser = isLoggedIn();

  const product = !!products && !!products[0] ? products[0] : null;
  const teamRegistration = product ? product.team_registrations : false;
  const productName = !!product ? product.name : '';
  const isFamilyBowling = !!product && product.league_id === 1 ? true  : false;
  const teamMembers = !!product ? product.team_size : 1;
  const teamSize = !!product ? product.team_size : 0;
  const sessionFee = !!product ? product.session_fee : 0;
  const maxTeamMembers = teamMembers - 1;

  const checkDiscount = async (code, actions) => {
    setCheckingDiscount(true);
    if (code) {
      try {
        const codeResponse = await postApiRequest('/check/discount-code', { code });
        if (codeResponse.code) {
          // We have a discount code!
          setDiscount(codeResponse.code);
          addNotification({
            type: 'success',
            title: 'Discount code applied!',
            body: `The discount code ${code} has been applied to your order!`,
          });
        } else {
          // No code found, set an error
          actions.setErrors({ code: 'The code is not valid.' });
        }
      } catch (err) {
        console.log(err, 'error checking code');
      }
    }
    actions.setSubmitting(false);
    setCheckingDiscount(false);
  };

  const registeringInitialValues = !hasUser ? {
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    birthday: null,
    phone: '',
  } : {};
  const registeringValidationSchema = !hasUser ? {
    email: Yup.string().email('Invalid email address').required('Email is a required field'),
    password: Yup.string().required('Password is a required field').min(6, 'Must be at least 6 characters long.'),
    first_name: Yup.string().required('First name is a required field'),
    last_name: Yup.string().required('Last name is a required field'),
    birthday: Yup.date().typeError('Must be a valid date.'),
    phone: Yup.string()
    .test(
      'valid-phone',
      'Must be a valid phone number.',
      (val) => val === '' || typeof val === 'undefined' || validatePhone(val)
    )
    .required("You must provide a mobile phone number."),
  } : {};

  // Determine if we have any team members to validate
  const teamMembersInitialValues = {};
  const teamMembersValidationSchema = {};
  if (teamRegistration) {
    for (let i = 0; i < maxTeamMembers; i++) {
      teamMembersInitialValues[`name_${i}`] = '';
      teamMembersInitialValues[`birthday_${i}`] = null;
      teamMembersInitialValues[`email_${i}`] = '';
      teamMembersInitialValues[`phone_${i}`] = '';
      teamMembersValidationSchema[`name_${i}`] = Yup.string().nullable();
      teamMembersValidationSchema[`birthday_${i}`] = Yup.date().typeError('Must be a valid date').nullable();
      teamMembersValidationSchema[`email_${i}`] = Yup.string().email('Invalid email address').nullable();
      teamMembersValidationSchema[`phone_${i}`] = Yup.string()
      .test(
        'valid-phone',
        'Must be a valid phone number.',
        (val) => val === '' || typeof val === 'undefined' || validatePhone(val)
        // (val) => val === '' || typeof val === 'undefined' || validatePhone(val)
      ).nullable();
    }
    for (let i = 0; i <= maxTeamMembers; i++) {
      teamMembersInitialValues[`name_alt_${i}`] = '';
      teamMembersInitialValues[`birthday_alt_${i}`] = null;
      teamMembersInitialValues[`email_alt_${i}`] = '';
      teamMembersInitialValues[`phone_alt_${i}`] = '';
      teamMembersValidationSchema[`name_alt_${i}`] = Yup.string().nullable();
      teamMembersValidationSchema[`birthday_alt_${i}`] = Yup.date().typeError('Must be a valid date').nullable();
      teamMembersValidationSchema[`email_alt_${i}`] = Yup.string().email('Invalid email address').nullable();
      teamMembersValidationSchema[`phone_alt_${i}`] = Yup.string()
      .test(
        'valid-phone',
        'Must be a valid phone number.',
        (val) => val === '' || typeof val === 'undefined' || validatePhone(val)
        // (val) => val === '' || typeof val === 'undefined' || validatePhone(val)
      ).nullable();
    }
  } else {
    teamMembersInitialValues.team_choice = 'create';
    teamMembersInitialValues.new_team_name = '';
    teamMembersInitialValues.team_to_join = '';
    teamMembersValidationSchema.team_choice = Yup.string().required('You must choose a team option.');
  }

  const initialValues = {
    ...registeringInitialValues,
    ...teamMembersInitialValues,
    requested_team: '',
    zip: '',
    name_on_card: '',
    terms: false,
  };
  const validationSchema = {
    ...registeringValidationSchema,
    ...teamMembersValidationSchema,
    requested_team: teamRegistration ? Yup.string().required('You must provide a team name.') : Yup.string(),
    name_on_card: Yup.string().required('You must provide the name associated with the credit card.'),
    zip: Yup.string().required('Billing zip code is a required field'),
    terms: Yup.boolean().oneOf([true], 'You must agree to the terms continue.')
  };


  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: async (values, actions) => {
      setFormError(null);
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        actions.setSubmitting(false);
        return;
      }
      setLoading(true);

      // Perform additional validation on team fields, based on team_choice
      if (!teamRegistration) {
        if (values.team_choice === 'create' && !values.new_team_name) {
          actions.setSubmitting(false);
          formik.setFieldError('new_team_name', 'You must provide a team name.');
          setLoading(false);
          return;
        }
        if (values.team_choice === 'join' && !values.team_to_join) {
          actions.setSubmitting(false);
          formik.setFieldError('team_to_join', 'You must select a team to join.');
          setLoading(false);
          return;
        }
      }

      // Get a reference to a mounted CardElement.
      const cardElement = elements.getElement(CardElement);
      const { email, name_on_card: nameOnCard } = values;

      // Use your card Element with other Stripe.js APIs
      const { error: paymentError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: { email, name: nameOnCard },
      });

      if (paymentError) {
        // WE HAD A PAYMENT ERROR, DISPLAY IT
        actions.setSubmitting(false);
        addNotification({
          type: 'error',
          body: 'You provided incomplete or incorrect payment information.',
          timeout: 6000,
        });
        setLoading(false);
        return;
      } else {
        // Submit our data!
        try {
          // Grab any participants, toss them into an array
          const participants = [];
          const alternates = [];
          for (let i = 0; i < maxTeamMembers; i++) {
            if (values[`name_${i}`] && values[`birthday_${i}`] && (values[`phone_${i}`] || values[`email_${i}`])) {
              participants.push({
                name: values[`name_${i}`],
                phone: values[`phone_${i}`] || '',
                email: values[`email_${i}`] || '',
                birthday: values[`birthday_${i}`],
              })
            }
          }
          for (let i = 0; i <= maxTeamMembers; i++) {
            if (values[`name_alt_${i}`] && values[`birthday_alt_${i}`] && (values[`phone_alt_${i}`] || values[`email_alt_${i}`])) {
              alternates.push({
                name: values[`name_alt_${i}`],
                phone: values[`phone_alt_${i}`] || '',
                email: values[`email_alt_${i}`] || '',
                birthday: values[`birthday_alt_${i}`],
              })
            }
          }
          const valuesToSend = {
            ...values,
            participants,
            alternates,
            stripe_paymethod: paymentMethod,
            payment_type: 'stripe',
            discount: discountCode,
            referral_code: window.localStorage.rcode || null,
            cart: cart.items.map(item => {
              const { id, quantity } = item;
              return { quantity, product_id: id };
            }),
          }
          const registerResult = await postApiRequest('/checkout/process', valuesToSend);
          // console.log('result of register - this MAY CONTAIN A NEW USER/TOKEN!', registerResult);

          const { status, token_response: tokenResponse } = registerResult || {};
          if (status !== 'success') {
            // SET AN ERROR, this should never happen
            actions.setSubmitting(false);
            setFormError('There was an unknown error processing your registration, please try again.');
            setLoading(false);
            return;
          }
          // IF we have a token response, auth that user!
          if (tokenResponse) {
            try {
              setJwt(tokenResponse.access_token);
              const newUser = await postApiRequest('/auth/me');
              setUser(newUser);
            } catch (authErr) {
              // JUST IN CASE we hit an error here, we don't want to stop, since we have accepted payment, just redirect to dash
              console.log(authErr, 'the auth error, should never happen');
            }
          }
          // Set a success message and redirect to the dashboard
          const [firstCartItem] = cart.items;
          const { id: productId } = firstCartItem || {};
          emptyCart();
          addNotification({
            type: 'success',
            title: 'Congratulations!',
            body: 'Your registration was successful.',
          });
          navigate(`/thank-you?prod=${productId}`);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err, 'the error from the server');
          const { response: errorResponse } = err;
          if (errorResponse.status === 422) {
            addNotification({
              type: 'error',
              body: 'Please correct the errors to continue.',
              timeout: 4000,
            });
            const { data: errorData } = errorResponse;
            const { errors, error: genericError } = errorData || {};
            const newErrors = {};
            if (errors) {
              const errorFieldNames = Object.keys(errors);
              for (let i = 0; i < errorFieldNames.length; i++) {
                const errorFieldName = errorFieldNames[i];
                newErrors[errorFieldName] = errors[errorFieldName].join(' ');
              }
              actions.setErrors(newErrors);
            }
            if (genericError) {
              setFormError(genericError);
            }
          } else if (errorResponse.status !== 401) {
            addNotification({
              type: 'error',
              body: 'There was an unknown error, please try again.',
              timeout: 6000,
            });
          }
        }
      }
    },
  });

  useEffect(() => {
    setInitialized(true);
  }, []);

  // Removing an item from the cart, for NOW, will just empty the cart and move you back home
  const removeItem = () => {
    emptyCart();
    addNotification({
      type: 'info',
      body: 'Your shopping cart has been emptied.',
    });
    navigate('/');
  };


  // Don't show checkout if we have not initialized the page (aka we are in the browser)
  if (!initialized) {
    return null;
  }

  const perMemberFee = teamSize ? Math.round(total / teamSize) : 0;

  return (
      
    <Container hideNav>
      <SEO title="Checkout" />
      <div className="h-full bg-white">
        <div className="h-full">
          <main className="lg:min-h-full lg:overflow-hidden lg:flex lg:flex-row-reverse">
            <h1 className="sr-only">Checkout</h1>

            {/* Mobile order summary */}
            <MobileOrderSummary
              products={products}
              subtotal={subtotal}
              total={total}
              discountCode={discountCode}
              discountAmount={discountAmount}
              removeItem={removeItem}
              checkDiscount={checkDiscount}
              checking={checkingDiscount}
            />
            

            {/* Order summary */}
            <OrderSummary
              products={products}
              subtotal={subtotal}
              total={total}
              discountCode={discountCode}
              discountAmount={discountAmount}
              removeItem={removeItem}
              checkDiscount={checkDiscount}
              checking={checkingDiscount}
            />

            {/* Checkout form */}
            <section
              aria-labelledby="payment-heading"
              className="flex-auto overflow-y-auto px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:px-8 lg:pt-0 lg:pb-24"
            >
              <form onSubmit={formik.handleSubmit}>
                <h2 id="payment-heading" className="sr-only">
                  League registration form
                </h2>

                <div className="max-w-lg mx-auto lg:pt-16">

                  {!!formError && <Alert type="error" body={formError} />}

                  {!hasUser && <AccountFormSection formik={formik} teamRegistration={teamRegistration} />}
                  {hasUser && <UserFormSection user={user} />}

                  {!!teamRegistration && !!productName && (
                    <>
                      <p className="my-8 text-lg text-gray-700">
                        Due to the popularity of this league and limited number 
                        of team spots, an  <NumberFormat value={total} displayType="text" thousandSeparator prefix="$" /> registration fee is required to guarantee a team spot in the {productName}.
                      </p>

                      {!!perMemberFee && (
                        <p className="my-8 text-lg text-gray-700">
                          If you are the team captain, you'll pay the <NumberFormat value={total} displayType="text" thousandSeparator prefix="$" /> registration fee, 
                          and can collect <NumberFormat value={perMemberFee} displayType="text" thousandSeparator prefix="$" /> from your team members.
                        </p>
                      )}
                    </>
                  )}

                  {!!teamRegistration && (
                    <FormSection title="Team information">
                      <div className="grid grid-cols-12 gap-y-6 gap-x-4">
                        <div className="col-span-full">
                          <Field
                            formik={formik}
                            field={{
                              name: 'requested_team',
                              type: 'text',
                              required: !!teamRegistration,
                              label: isFamilyBowling ? `Child's Name` : 'Team Name',
                              helpText: isFamilyBowling ? '' : 'Provide a name for your team.',
                            }}
                          />
                        </div>
                      </div>
                    </FormSection>
                  )}
                  <TeamMemberFormSection teamRegistration={teamRegistration} teamMembers={teamMembers} formik={formik} total={total} team sessionFee={sessionFee} teamSize={teamSize} />

                  {!teamRegistration && !!product && <SmartTeamRegistrationSection product={product} formik={formik} />}

                  <FormSection title="Payment details" noBottomMargin >
                  
                    {/* <button
                      type="button"
                      className="w-full flex items-center justify-center bg-black border border-transparent text-white rounded-md py-2 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    >
                      Pay With PayPal
                    </button>

                    <OrDivider /> */}

                    <div className="grid grid-cols-12 gap-y-6 gap-x-4">
                      <div className="col-span-full sm:col-span-6">
                        <Field
                          formik={formik}
                          field={{
                            name: 'name_on_card',
                            type: 'text',
                            label: 'Name on card',
                          }}
                        />
                      </div>
                      <div className="col-span-full sm:col-span-6">
                        <Field
                          formik={formik}
                          field={{
                            name: 'zip',
                            type: 'text',
                            label: 'Billing zip',
                          }}
                        />
                      </div>

                      <div className="col-span-full">
                        <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">
                          Credit card
                        </label>
                        <div className="mt-1">
                          {/* <input
                            type="text"
                            id="card-number"
                            name="card-number"
                            autoComplete="cc-number"
                            className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          /> */}
                          <CardElement
                            options={{ hidePostalCode: true }}
                            className="block w-full mb-2 py-3 pl-3 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      
                    </div>

                    <div className="mt-6 flex space-x-2">
                      <Field
                        formik={formik}
                        field={{
                          name: 'terms',
                          type: 'checkbox',
                          title: 'I agree to the terms and conditions.',
                          subtitle: <a href="https://kidsbowlfree.com/terms.php" target="_blank">View complete terms and conditions &raquo;</a>,
                        }}
                      />
                    </div>

                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className="w-full mt-6 bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Pay <NumberFormat value={total} displayType="text" thousandSeparator prefix="$" />
                    </button>

                    <p className="flex justify-center text-sm font-medium text-gray-500 mt-6">
                      <LockClosedIcon className="w-5 h-5 text-gray-400 mr-1.5" aria-hidden="true" />
                      Payment details secured by Stripe
                    </p>

                  </FormSection>
                </div>
              </form>
            </section>
          </main>
        </div>
      </div>
      <LoadingModal loading={loading} text="Processing..." />
    </Container>

  );
}

