import React from 'react';
import FormSectionTitle from './form-section-title';

export default function FormSection({ title, children, noBottomMargin }) {
  const classes = !noBottomMargin ? 'mt-4 mb-10 sm:mb-16' : 'mt-4';
  return (
    <div className={classes}>
      <FormSectionTitle title={title} />
      {children}
    </div>
  );
}
