import React from 'react';
import { Disclosure } from '@headlessui/react';
import NumberFormat from 'react-number-format';
import ProductListItem from './product-list-item';
import DiscountCodeForm from './discount-code-form';

export default function MobileOrderSummary({ products, subtotal, discountCode, discountAmount, total, removeItem, checkDiscount, checking }) {
  return (
    <section aria-labelledby="order-heading" className="bg-gray-50 px-4 py-6 sm:px-6 lg:hidden">
      <Disclosure as="div" className="max-w-lg mx-auto">
        {({ open }) => (
          <>
            <div className="flex items-center justify-between">
              <h2 id="order-heading" className="text-lg font-medium text-gray-900">
                Your Order
              </h2>
              <Disclosure.Button className="font-medium text-indigo-600 hover:text-indigo-500">
                {open ? <span>Hide full summary</span> : <span>Show full summary</span>}
              </Disclosure.Button>
            </div>

            <Disclosure.Panel>
              <ul className="divide-y divide-gray-200 border-b border-gray-200">
                {products.map((product) => <ProductListItem key={product.id} product={product} removeItem={removeItem} />)}
              </ul>

              <DiscountCodeForm isMobile checkDiscount={checkDiscount} checking={checking} />

              <dl className="text-sm font-medium text-gray-500 mt-10 space-y-6">
                <div className="flex justify-between">
                  <dt>Subtotal</dt>
                  <dd className="text-gray-900"><NumberFormat fixedDecimalScale decimalScale={2} value={subtotal} displayType="text" thousandSeparator prefix="$" /></dd>
                </div>
                <div className="flex justify-between">
                  <dt className="flex">
                    Discount
                    {!!discountCode && (
                      <span className="ml-2 rounded-full bg-gray-200 text-xs text-gray-600 py-0.5 px-2 tracking-wide">
                        {discountCode}
                      </span>
                    )}
                  </dt>
                  {!!discountCode && (
                    <dd className="text-gray-900">-<NumberFormat fixedDecimalScale decimalScale={2} value={discountAmount} displayType="text" thousandSeparator prefix="$" /></dd>
                  )}
                  {!discountCode && (
                    <dd className="text-gray-900">--</dd>
                  )}
                </div>
              </dl>
            </Disclosure.Panel>

            <p className="flex items-center justify-between text-sm font-medium text-gray-900 border-t border-gray-200 pt-6 mt-6">
              <span className="text-base">Total</span>
              <span className="text-base"><NumberFormat fixedDecimalScale decimalScale={2} value={total} displayType="text" thousandSeparator prefix="$" /></span>
            </p>
          </>
        )}
      </Disclosure>
    </section>
  );
}