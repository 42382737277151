import React from 'react';
import NumberFormat from 'react-number-format';
import { formatDay } from '../../utils/helpers';

export default function ProductListItem({ product, removeItem }) {
  return (
    <li className="flex py-6 space-x-6">
      <img
        src={product.image}
        alt={product.name}
        className="flex-none w-40 h-40 object-center object-cover bg-gray-200 rounded-md"
      />
      <div className="flex flex-col justify-between space-y-4">
        <div className="text-sm font-medium space-y-2">
          <h3 className="text-gray-900 font-semibold">{product.name}</h3>
          <p className="text-gray-900"><NumberFormat value={product.price} displayType="text" thousandSeparator prefix="$" /></p>
          <p className="text-gray-500">Starts: {formatDay(product.start_date, 'M/D/Y')}</p>
        </div>
        <div className="flex space-x-4">
          {/* <button type="button" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
            Edit
          </button> */}
          {/* <div className="flex border-l border-gray-300 pl-4"> */}
            <button onClick={removeItem} type="button" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
              Remove
            </button>
          {/* </div> */}
        </div>
      </div>
    </li>
  );
}