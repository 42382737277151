import { RadioGroup } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { getApiRequest } from '../../utils/request';
import Loadable from '../data/loadable';
import FormSection from './form-section';
import { classNames } from '../../utils/helpers';
import Field from '../form/field';


const choices = [
  {
    value: 'create',
    name: 'Create a new team',
    description: 'Create a new team and invite your friends to join.',
  },
  {
    value: 'join',
    name: 'Join a team',
    description: 'Choose a team from those already registered for this league.',
    // description: 'Choose a team from the list of teams.',
  },
  {
    value: 'free_agent',
    name: 'Become a free agent',
    description: 'Join as a free agent and find a team when the league starts!',
    // description: 'Not sure who you want to play with? ',
  },
];

export default function SmartTeamRegistrationSection({ product, formik }) {
  const [loading, setLoading] = useState(false);
  const [teamOptions, setTeamOptions] = useState(null);
  const [selected, setSelected] = useState(choices[0])

  const { id } = product;

  const handleChange = (value) => {
    setSelected(value);
    formik.setFieldValue('team_choice', value.value);
  }

  // useEffect(() => {
  //   const init = async () => {
  //     setLoading(true);
  //     // Load our team options
  //     try {
  //       const results = await getApiRequest(`/options/products/${id}/teams`);
  //       console.log(results, 'the team results');
  //       setTeamOptions(results.options);
  //     } catch (err) {
  //       console.log(err, 'error loading teams results');
  //     }
  //     setLoading(false);
  //   }
  //   init();
  // }, []);

  // console.log(selected, 'selected');

  const { value: selectedValue } = selected || {};

  // const hasOptions = !!teamOptions && !!teamOptions.length;

  return (
    <Loadable loading={loading}>
      <FormSection title="Join or Create a Team">
        <div className="grid grid-cols-12 gap-y-6 gap-x-4">
          <div className="col-span-full">
            
            <RadioGroup value={selected} onChange={handleChange}>
              <div className="mt-1 bg-white rounded-md shadow-sm -space-y-px">
                {choices.map((choice, choiceIdx) => (
                  <RadioGroup.Option
                    key={choice.value}
                    value={choice}
                    className={({ checked }) =>
                      classNames(
                        choiceIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                        choiceIdx === choices.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                        checked ? 'bg-sky-50 border-sky-200 z-10' : 'border-gray-200',
                        'relative border p-4 flex cursor-pointer focus:outline-none'
                      )
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <span
                          className={classNames(
                            checked ? 'bg-sky-600 border-transparent' : 'bg-white border-gray-300',
                            active ? 'ring-2 ring-offset-2 ring-sky-500' : '',
                            'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                          )}
                          aria-hidden="true"
                        >
                          <span className="rounded-full bg-white w-1.5 h-1.5" />
                        </span>
                        <div className="ml-3 flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className={classNames(
                              checked ? 'text-sky-900' : 'text-gray-900',
                              'block text-sm font-medium'
                            )}
                          >
                            {choice.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={classNames(checked ? 'text-sky-700' : 'text-gray-500', 'block text-sm')}
                          >
                            {choice.description}
                          </RadioGroup.Description>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>

            {selectedValue === 'create' && (
              <div className="mt-6">
                <Field
                  formik={formik}
                  field={{
                    name: 'new_team_name',
                    type: 'text',
                    required: true,
                    label: 'Team Name',
                    helpText: 'Provide a name for your new team. Bowlers that register later will be able to join your team by selecting "Join a team" and choosing from the list.',
                  }}
                />
              </div>
            )}
            {selectedValue === 'join' && (
              <div className="mt-6">
                <Field
                  formik={formik}
                  field={{
                    name: 'team_to_join',
                    type: 'select',
                    optionsUrl: `/options/products/${id}/teams`,
                    required: true,
                    label: 'Choose Team',
                    helpText: 'Choose a team that you would like to join. If there are no teams yet, you should create a new one, or join as a free agent.',
                  }}
                />
              </div>
            )}

          </div>
        </div>
      </FormSection>
    </Loadable>
  );
}
