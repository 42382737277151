import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import HelpText from '../form/help-text';

export default function DiscountCodeForm({ checkDiscount, isMobile, checking }) {

  const formik = useFormik({
    initialValues: { code: '' },
    validationSchema: Yup.object({
      code: Yup.string(),
    }),
    onSubmit: async (values, actions) => {
      checkDiscount(values.code, actions);
    }
  });

  const formClasses = isMobile ? 'mt-10' : '';
  const codeIdField = isMobile ? 'discount-code-mobile' : 'discount-code';
  const hasError = formik.touched.code && formik.errors.code;
  const errorText = hasError ? formik.errors.code : null;
  const inputClasses = hasError ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500' : 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
  const btnClasses = formik.isSubmitting || checking ? 'cursor-not-allowed' : '';
  

  return (
    <form className={formClasses} onSubmit={formik.handleSubmit}>
      <label htmlFor={codeIdField} className="block text-sm font-medium text-gray-700">
        Discount code
      </label>
      <div className="flex space-x-4 mt-1">
        <input
          type="text"
          id={codeIdField}
          name="code"
          {...formik.getFieldProps('code')}
          className={`${inputClasses} block w-full rounded-md sm:text-sm`}
        />
        <button
          type="submit"
          disabled={formik.isSubmitting}
          className={`${btnClasses} bg-gray-200 text-sm font-medium text-gray-600 rounded-md px-4 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500`}
        >
          Apply
        </button>
      </div>
      <HelpText id={codeIdField} hasError={hasError} errorText={errorText} />
    </form>
  );

}