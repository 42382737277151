import React, { useState } from 'react';
import { PlusSmIcon as PlusSmIconSolid } from '@heroicons/react/solid';
import NumberFormat from 'react-number-format';
import Field from '../form/field';
import FormSection from './form-section';

function TeamMemberFields({ suffixes, label, formik, alt = false }) {
  if (!suffixes || !suffixes.length) {
    return null;
  }

  return (
    <>
      {suffixes.map((suffix, i) => {
        return (
          <div className="my-8 pb-4 border-b border-gray-100" key={suffix}>
            <h4 className="text-lg text-gray-800 mb-2">{label} #{alt ? i + 1 : i + 2}</h4>
            <div className="grid grid-cols-12 gap-y-4 gap-x-4">
              <div className="col-span-full sm:col-span-6">
                <Field
                  formik={formik}
                  field={{
                    name: `name_${suffix}`,
                    type: 'text',
                    label: 'Name',
                  }}
                />
              </div>
              
              <div className="col-span-full sm:col-span-6">
                <Field
                  formik={formik}
                  field={{
                    name: `birthday_${suffix}`,
                    type: 'date',
                    label: 'Birthday',
                  }}
                />
              </div>
              <div className="col-span-full sm:col-span-6">
                <Field
                  formik={formik}
                  field={{
                    name: `phone_${suffix}`,
                    type: 'phone',
                    label: 'Mobile phone',
                  }}
                />
              </div>
              <div className="col-span-full sm:col-span-6">
                <Field
                  formik={formik}
                  field={{
                    name: `email_${suffix}`,
                    type: 'email',
                    label: 'Email',
                  }}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

function canShowAlternatesButton(formik, suffixes, maxAlternates, currentAlternates) {
  if (currentAlternates === maxAlternates) {
    return false;
  }
  // Every name (at least) for a team must be filled out before you can start adding alternates
  for (let i = 0; i < suffixes.length; i++) {
    const value = formik.values[`name_${suffixes[i]}`];
    if (!value) {
      return false;
    }
  }
  return true;
}

export default function TeamMemberFormSection({ formik, teamRegistration, teamMembers, teamSize, total, sessionFee }) {
  const [alternates, setAlternates] = useState(0);
  if (!teamRegistration) {
    return null;
  }
  const maxMembers = teamMembers - 1;
  if (!maxMembers) {
    return null;
  }
  const suffixes = [];
  for (let i = 0; i < maxMembers; i++) {
    suffixes.push(`${i}`);
  }

  const maxAlternates = teamMembers;
  const alternateSuffixes = [];
  for (let j = 0; j < alternates; j++) {
    alternateSuffixes.push(`alt_${j}`);
  }

  const canAddAlts = canShowAlternatesButton(formik, suffixes, maxAlternates, alternates);

  return (
    <FormSection title="Add team members" >
      <p className="text-gray-600 text-sm mb-4">You are automatically added to your team as the captain.  If you know who your team members will be, please add their information now.  If you aren't quite sure yet, you can add them later.</p>
      <TeamMemberFields label="Team member" suffixes={suffixes} formik={formik} />
      
      <p className="text-gray-600 text-sm mb-4">
        Alternate team members can be added at anytime and they can fill in when a regular team member can not participate. Teams may use more than {teamSize} bowlers, but only need to pay the{' '} 
        <NumberFormat value={total} displayType="text" thousandSeparator prefix="$" /> registration fee and <NumberFormat value={sessionFee * teamSize} displayType="text" thousandSeparator prefix="$" /> session fee.
      </p>
      <button
        type="button"
        disabled={!canAddAlts}
        onClick={() => canAddAlts && setAlternates(alternates + 1)}
        className={`group mx-auto my-6 p-1 pr-6 rounded-md flex items-center focus:outline-none focus:ring-2 focus:ring-indigo-500 ${canAddAlts ? 'cursor-pointer bg-blue-600' : 'cursor-not-allowed bg-blue-400'}`}
      >
        <span className="w-8 h-8 rounded-full flex items-center justify-center text-blue-200">
          <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />
        </span>
        <span className="ml-1 text-sm font-medium text-white group-hover:text-gray-100">
          Add Alternate Team Member
        </span>
      </button>
      <TeamMemberFields label="Alternate team member" alt suffixes={alternateSuffixes} formik={formik} />

    </FormSection>
  );
}
